import api from "../axios_service.js";

export async function fetchPerfis() {
  const response = await api.get("/admin/perfis");
  return response.data.result;
}

export async function fetchPerfil(id) {
  const response = await api.get(`/admin/perfis/${id}`);
  return response.data.result;
}

export function putPerfil(id, perfil) {
  let body = {};
  for (let key in perfil) {
    body[key] = perfil[key];
  }
  return api.put(`/admin/perfis/${id}`, body);
}

export function postPerfil(perfil) {
  let body = {};
  for (let key in perfil) {
    body[key] = perfil[key];
  }
  return api.post("/admin/perfis/add", body);
}
